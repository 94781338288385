import { service } from '@/ajax/request';

export const symptomsInfoList = (params) => {
    return service({
        url: '/symptomsCare/symptomsInfoPage',
        method: 'POST',
        params,
    })
}

export const getBodyCode = () => {
    return service({
        url: '/symptomsCare/selectBodyCode',
        method: 'POST',
    })
}

export const updateStatus = (params) => {
    return service({
        url: '/symptomsCare/updateStatus',
        method: 'POST',
        params,
    })
}

export const saveSymptomsInfo = (params) => {
    return service({
        url: '/symptomsCare/saveOrUpdate',
        method: 'POST',
        params,
    })
}

export const saveSymptomsPlanInfo = (params) => {
    return service({
        url: '/symptomsPlan/editPlan',
        method: 'POST',
        params,
    })
}

export const getSymptomsById = (params) => {
    return service({
        url: '/symptomsCare/getById',
        method: 'POST',
        params,
    })
}

export const getPlanById = (params) => {
    return service({
        url: '/symptomsPlan/getPlanByCareId',
        method: 'POST',
        params,
    })
}