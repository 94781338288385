
import { Ref, Mixins, Component, InjectReactive, Watch } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import ETable from "@/components/ETable.vue";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";
import EditDialog from "./components/editDialog.vue";
import ProjecctDialog from "./components/projectDialog.vue";
import { symptomsInfoList, updateStatus, getSymptomsById, getPlanById } from "@/api/symptoms";
import { GenderEnum } from "@/enum/symptoms.enum";

import { cloneDeep } from "lodash"

const baseForm = {
        name: "",
        gender: "",
        homeGuide: "",
        plan: "",
        planItemDTOs: [{
            careMethod: "",
            effect: "",
            name: "",
            symptomsImgs: []
        }],
        symptomsCareId: ""
    }

@Component({
    components: { ETable, EditDialog, ProjecctDialog }
})
export default class Symptoms extends Mixins(loading, tablePagination, Resize) {
    // @InjectReactive() currentTab !: number;
    // @Ref("ruleForm") ruleFormRef !: Form;
    // healthNameOrShowName = "";
    EditDialogVisible: boolean = false;
    ProjecctDialogVisible: boolean = false;
    dialogTitle = "";
    // symptomsData = {};
    // projectData = {};
    queryForm = {
        name: "",
    };
    // symptomsCareId = "";
    itemTotal = 0;
    tableData = [];

    columns = [
        { label: "ID", prop: "id" },
        { label: "症状名称", prop: "name" },
        { label: "部位", prop: "", formatter: (row: any) => row.bodyNamesList.join("，") },
        { label: "适用性别", prop: "", formatter: (row: any) => GenderEnum[row.gender] },
        { label: "状态", prop: "status" },
        { label: "操作人", prop: "modifiedBy" },
        { label: "最后操作时间", prop: "modifiedTime" },
        { label: "操作", prop: "action" }
    ]

    symptomsData = {
        id: "",
        name: "",
        title: "",
        gender: 0,
        bodyCodes: [],
        imgUrl: [],
        remark: "",
        common: "",
        status: true,
        symptomsImgs: [
            {
                name: "",
                imgUrl: [],
                objType: 0
            },
            {
                name: "",
                imgUrl: [],
                objType: 0
            },
            {
                name: "",
                imgUrl: [],
                objType: 0
            }
        ],
        unhealthy: ""
    }

    projectData = {
        name: "",
        gender: "",
        homeGuide: "",
        plan: "",
        planItemDTOs: [{
            careMethod: "",
            effect: "",
            name: "",
            symptomsImgs: []
        }],
        symptomsCareId: ""
    }

    mounted() {
        this.windowResize(320);
        this.getData();
    }

    searchData() {
        this.resetCurrent();
        this.getData();
    }

    getData() {
        const params = {
            pageNum: this.currentPage,
            pageSize: this.pageSize,
            ...this.queryForm,
        }
        this.showLoading();

        symptomsInfoList(params).then((res) => {
            // res.data.list.forEach((el) => {
            //     el.groupTypeDesc = this.groupTypeOptions.find(item => item.value == el.groupType).label;
            // })
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        })
    }

    handleEnableState(row) {
        updateStatus({ symptomsCareId: row.id, status: row.status });
        this.getData();
    }

    goProject(row) {
        this.ProjecctDialogVisible = true;
        getPlanById({ symptomsCareId: row.id }).then((res) => {
            this.projectData = res.data || cloneDeep(baseForm);
            const { planItemDTOs } = this.projectData;
            const _planItemDTOs = planItemDTOs?.map((plan) => {
                return {
                    ...plan,
                    symptomsImgs: plan?.symptomsImgs?.map((IMG: any) => ({ url: IMG.imgUrl }))
                }
            })
            Object.assign(this.projectData, { ...res.data, name: row.name, gender: row.gender,symptomsCareId: row.id, planItemDTOs: _planItemDTOs });
        })
    }
    handleEdit(row) {
        this.dialogTitle = row.id ? "编辑" : "新增";
        if (row.id) {
            this.$nextTick(() => {
                getSymptomsById({ symptomsCareId: row.id }).then((res) => {
                    const { imgUrl, status, symptomsImgs } = res.data;
                    const imgs = symptomsImgs.map((el) => ({ ...el, imgUrl: [{url: el.imgUrl}] }));
                    Object.assign(this.symptomsData, { ...res.data, status: Boolean(status), imgUrl: [{url: imgUrl}], symptomsImgs: imgs })
                })
            })
        }
        else {
            this.symptomsData.id = "";
        }
        this.EditDialogVisible = true;
    }

    editConfirm() {
        this.getData();
        this.EditDialogVisible = false;
        this.ProjecctDialogVisible = false;
    }
}

