
import { Component, Prop, Vue, Watch, Ref, Mixins, PropSync } from "vue-property-decorator";
import UploadImg from "@/components/uploadImg.vue";
import config from "@/config/default";
import ETable from "@/components/ETable.vue";
import TablePagination from "@/mixins/tablePagination";
import Resize from "@/mixins/resize";
import { saveSymptomsPlanInfo } from "@/api/symptoms";
import { Form } from "element-ui/types";
import { cloneDeep } from "lodash";

@Component({
    components: {
        ETable,
        UploadImg,
    }
})
export default class EditDialog extends Mixins(Vue, TablePagination, Resize) {
    // @Ref("form") formRef: Form;
    @Ref("validateForm") formRef: Form;
    @PropSync("formData") private formDataSync!: any;
    // @Prop() private formDataSync!: any;
    @Prop([String]) private dialogTitle!: string;
    @Prop([Boolean]) private dialogVisible!: boolean;
    // 初始数据可以直接声明为实例的 property
    // roleList: Array<Role> = [];

    formRules = {
        name: { required: true, message: "请输入名称" },
        careMethod: { required: true, message: "请输入调理方法", trigger: "blur" },
        effect: { required: true, message: "请输入作用", trigger: "blur" },
        symptomsImgs: { required: true, message: "请上传图片", trigger: "blur" }
    };

    columns = [
        { label: "序号", width: "60px", type: "index" },
        { label: "名称", prop: "caseName" },
        { label: "图片", prop: "caseImgUrl" },
        { label: "操作", prop: "action" }
    ];

    fileList = [];
    uploadImageUrl = `${config.uploadImg}permutation`;
    dialogImageUrl = ""; // 图片预览
    dialogPreviewVisible = false;
    saveLoading = false;

    bodyOptions = [];

    mounted() {
        this.windowResize(320);
    }

    dialogClose() {
        this.fileList = [];
        this.$emit("closeProjecctDialog");
        this.saveLoading = false;
        this.formRef?.resetFields();
        this.formDataSync.planItemDTOs = [{
            careMethod: "",
            effect: "",
            name: "",
            symptomsImgs: []
        }];
    }

    handleEdit() {
        if (this.formDataSync.planItemDTOs.length >= 6) return;
        this.formDataSync.planItemDTOs.push({
            name: "",
            careMethod: "",
            effect: "",
            symptomsImgs: []
        });
    }

    deleteRow(index) {
        // if (this.formDataSync.caseList.length <= 1) return;
        this.formDataSync.planItemDTOs.splice(index, 1);
    }

    dialogConfirm() {
        this.formRef?.validate((valid) => {
            if (valid) {
                const params = cloneDeep(this.formDataSync);
                console.log(params)
                params.planItemDTOs?.forEach((plan) => {
                    // const imgInfo = plan.symptomsImgs[0].imgUrl; // [{},{}]
                    plan.symptomsImgs = plan.symptomsImgs?.map((img) => {
                        return {
                            imgUrl: img.url,
                            name: 1,
                            objType: 1
                        }
                    })
                })

                saveSymptomsPlanInfo(params).then((res) => {
                    this.$message.success("保存成功");
                    this.$emit('projectDialogConfirm');
                    this.dialogClose();
                });
            } else {
                return false;
            }
        })

    }
}
