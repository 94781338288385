
import { Component, Prop, Vue, Watch, Ref, Mixins, PropSync } from "vue-property-decorator";
import { Form } from "element-ui/types";
import UploadImg from "@/components/uploadImg.vue";
import ETable from "@/components/ETable.vue";
import TablePagination from "@/mixins/tablePagination";
import Resize from "@/mixins/resize";
import { getBodyCode, saveSymptomsInfo } from "@/api/symptoms";

@Component({
    components: {
        ETable,
        UploadImg,
    }
})
export default class EditDialog extends Mixins(Vue, TablePagination, Resize) {
    @Ref("validateForm") formRef: Form;

    @PropSync("formData") private formDataSync!: any;
    // @Prop([String]) private symptomsCareId!: string;
    @Prop([String]) private dialogTitle!: string;
    @Prop([Boolean]) private dialogVisible!: boolean;
    // 初始数据可以直接声明为实例的 property
    // roleList: Array<Role> = [];

    formRules = {
        name: [{ required: true, message: "请输入症状名称", trigger: "blur" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        gender: [{ required: true, message: "请选择适用性别", trigger: "change" }],
        bodyCodes: [{ required: true, message: "请选择部位", trigger: "change" }],
        imgUrl: [{ required: true, message: "请上传症状图", trigger: "blur" }]
    };

    fileList = [];
    saveLoading = false;

    bodyOptions = [];

    getbodyOptions() {
        getBodyCode().then((res) => {
            this.bodyOptions = res.data;
        })
    }

    mounted() {
        this.windowResize(320);
        this.getbodyOptions();
    }

    dialogClose() {
        this.fileList = [];
        this.$emit('closeEditDialog');
        // (this.$refs['validateForm'] as Form).resetFields();
        this.formRef?.resetFields();
        this.formDataSync.symptomsImgs = [
            {
                name: "",
                imgUrl: "",
                objType: 0
            },
            {
                name: "",
                imgUrl: "",
                objType: 0
            },
            {
                name: "",
                imgUrl: "",
                objType: 0
            },
        ];
        this.saveLoading = false;
    }

    deleteBodyName(index) {
        this.formDataSync.bodyName.splice(index, 1);
    }

    handleEdit() {
        if (this.formDataSync.symptomsImgs.length >= 9) return;
        this.formDataSync.symptomsImgs.push({
            name: "",
            imgUrl: "",
            objType: 0
        });
    }

    deleteRow(row) {
        // if (this.tableData.length <= 1) return;
        this.formDataSync.symptomsImgs.splice(this.formDataSync.symptomsImgs.indexOf(row), 1);
    }

    saveSymptomsInfo() {
        //if(!this.formDataSync.name || !this.formDataSync.title || !this.formDataSync.gender || !this.formDataSync.bodyCodes.length || !this.formDataSync.imgUrl.length) return;
        this.formRef?.validate((valid) => {
            if (valid) {
                const _status = Number(this.formDataSync.status);
                const _imgUrl = this.formDataSync.imgUrl[0].url;
                const _symptomsImgs = this.formDataSync.symptomsImgs.map((el) => ({
                    imgUrl: el.imgUrl[0]?.url,
                    name: el.name,
                    objType: 0
                }));
                this.saveLoading = true;
                saveSymptomsInfo({ ...this.formDataSync, status: _status, imgUrl: _imgUrl, symptomsImgs: _symptomsImgs }).then((res) => {
                    this.$message.success("保存成功");
                    this.$emit('editDialogConfirm');
                    this.dialogClose();
                }).finally(() => {
                    this.saveLoading = false;
                })
            } else {
                return false;
            }
        })
    }
}
